import { CustomEvents } from "./customEventTypesEnum";
import { AllowedEvents } from "./customTypes";
import { removeNullValues } from "../helper/removeNullValues";

// Custom MKM events
export const useCustomEvents = () => {
  const pushCustomEvent = async (event: CustomEvents, data: AllowedEvents) => {
    // this needs to happen to create an independent copy of the data object, otherwise in case where we fire events
    // in quick succession, the data object will be overwritten and the last event will contain all the data from the
    // previous events
    const eventData = JSON.parse(JSON.stringify(data));
    const cleanedData = removeNullValues(eventData);
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({ event, ...cleanedData });
  };

  return {
    CustomEvents,
    pushCustomEvent,
  };
};
