export enum CustomEvents {
  VIEW_SEARCH_RESULTS = "view_search_results",
  BROCHURE_REQUEST_SUBMIT = "brochureRequest_formsubmit",
  CREATE_CASH_ACCOUNT_SUBMIT = "createCashAccount_formsubmit",
  CREATE_CREDIT_ACCOUNT_SUBMIT = "applyForCreditAccount_formsubmit",
  KITCHEN_ENQUIRY_SUBMIT = "kitchenEnquiry_formsubmit",
  BATHROOM_ENQUIRY_SUBMIT = "bathroomEnquiry_formsubmit",
  TOOL_HIRE_ENQUIRY_SUBMIT = "toolHireEnquiry_formSubmit",
  BRANCH_TELEPHONE_INTERACTION = "branchTelephoneNo_interaction",
  BRANCH_EMAIL_ADDRESS_INTERACTION = "branchEmailAddress_interaction",
  CATEGORY_PAGE_VIEW = "category_page_view",
  LOGIN = "login",
}
