import type { CreateLoginResponse } from "mkm-api";
import type { UpdateCustomerContactPreferenceParams } from "mkm-api";
import type { UpdateCustomerParams } from "mkm-api";
import type { UpdateUserEmailParams } from "mkm-api";
import type { UpdateUserPasswordParams } from "mkm-api";
import { toRefs } from "@vueuse/core";
import { useCustomEvents } from "mkm-gtm";

export type AddressType = {
  address_line_1: string | null;
  address_line_2: string | null;
  address_line_3: string | null;
  address_line_4: string | null;
  address_line_5: string | null;
  postcode: string;
};

export type AccountType = {
  id: string;
  branch_id: number;
  type: "CREDIT" | "CASH";
  terms: string;
  business_type: string;
  name: string;
  address: AddressType;
};

export type ProfileType = {
  title: string;
  first_name: string;
  last_name: string;
  landline: string;
  mobile: string;
  opt_in_email: boolean;
  opt_in_sms: boolean;
  opt_in_mail: boolean;
  opt_in_phone: boolean;
};

export type UserType = {
  status?: number;
  id: number;
  email: string;
  web_admin: boolean;
  account: AccountType;
  profile: ProfileType;
};

export interface UseUserState {
  user: UserType | null;
}

export type UserExistsResponse = {
  status: number;
};

const checkIfUserExists = async (email: string): Promise<boolean> => {
  const { NUXT_PUBLIC_API_PROTOCOL, NUXT_PUBLIC_API_BASE_URL } = useRuntimeConfig().public;
  const headers = useRequestHeaders();

  const { data, error } = await useAsyncData("registerEmail", () =>
    $fetch<UserExistsResponse>(`${NUXT_PUBLIC_API_PROTOCOL}://${NUXT_PUBLIC_API_BASE_URL}/middleware/registerEmail`, {
      headers,
      body: JSON.stringify({ email: email }),
      method: "POST",
      credentials: "include",
    }),
  );

  useHandleError(error.value);

  const status = data.value?.status;

  return status !== undefined && (status < 200 || status > 299);
};

const updateUserPassword = async (payload: UpdateUserPasswordParams) => {
  const { NUXT_PUBLIC_API_PROTOCOL, NUXT_PUBLIC_API_BASE_URL } = useRuntimeConfig().public;
  const headers = useRequestHeaders();

  const { data, error } = await useAsyncData("updateUserPassword", () =>
    $fetch<UserType>(`${NUXT_PUBLIC_API_PROTOCOL}://${NUXT_PUBLIC_API_BASE_URL}/middleware/updateUserPassword`, {
      headers,
      body: JSON.stringify(payload),
      method: "POST",
      credentials: "include",
    }),
  );
  return { data, error };
};

const useUser = () => {
  const state = useState<UseUserState>(`useUser`, () => ({
    user: null,
  }));

  const login = async (credentials: { email: string; password: string; isCheckout: boolean }) => {
    const { NUXT_PUBLIC_API_PROTOCOL, NUXT_PUBLIC_API_BASE_URL } = useRuntimeConfig().public;
    const headers = useRequestHeaders();

    const { pushCustomEvent, CustomEvents } = useCustomEvents();
    const { data: userData, error } = await useAsyncData("createLogin", () =>
      $fetch<CreateLoginResponse>(`${NUXT_PUBLIC_API_PROTOCOL}://${NUXT_PUBLIC_API_BASE_URL}/middleware/createLogin`, {
        headers,
        body: JSON.stringify(credentials),
        method: "POST",
        credentials: "include",
      }),
    );

    useHandleError(error.value);

    state.value.user = userData.value?.data;

    const userCategory = state.value?.user?.account.business_type.split("|");
    const isTrade = userCategory?.[0] === "NTrade" ? "NON-TRADE" : userCategory?.[0] === "Trade" ? "TRADE" : null;
    await pushCustomEvent(CustomEvents.LOGIN, {
      user_id: state.value?.user?.id ?? null,
      // eslint-disable-next-line max-lines
      customer_category_1: isTrade,
      customer_category_2: userCategory?.[1] ?? null,
      customer_category_3: userCategory?.[2] ?? null,
      account_type: state.value?.user?.account.type,
      account_owning_branch: state.value.user?.account.branch_id ?? null,
    });
  };

  const handleLogout = async () => {
    const { NUXT_PUBLIC_API_PROTOCOL, NUXT_PUBLIC_API_BASE_URL } = useRuntimeConfig().public;
    const headers = useRequestHeaders();

    const { error } = await useAsyncData("requestLogout", () =>
      $fetch<null>(`${NUXT_PUBLIC_API_PROTOCOL}://${NUXT_PUBLIC_API_BASE_URL}/middleware/requestLogout`, {
        headers,
        method: "POST",
        credentials: "include",
      }),
    );

    useHandleError(error.value);

    state.value.user = null;
  };

  const logout = async () => {
    await handleLogout();
    navigateTo("/login");
  };

  const fetchCustomer = async () => {
    const { NUXT_PUBLIC_API_PROTOCOL, NUXT_PUBLIC_API_BASE_URL } = useRuntimeConfig().public;
    const headers = useRequestHeaders();

    const { data: customerData, error } = await useAsyncData("getCustomer", () =>
      $fetch<UserType>(`${NUXT_PUBLIC_API_PROTOCOL}://${NUXT_PUBLIC_API_BASE_URL}/middleware/getCustomer`, {
        headers,
        method: "POST",
        credentials: "include",
      }),
    );

    if (!customerData.value || customerData.value.status !== 200) {
      state.value.user = null;

      return;
    }

    state.value.user = customerData.value;
  };

  const isAdmin = computed(() => state.value?.user?.web_admin === true);
  const isCredit = computed(() => state.value?.user?.account?.type === "CREDIT");

  const updateUserProfile = async (payload: UpdateCustomerParams) => {
    const { NUXT_PUBLIC_API_PROTOCOL, NUXT_PUBLIC_API_BASE_URL } = useRuntimeConfig().public;
    const headers = useRequestHeaders();

    const { data: customerData, error } = await useAsyncData("updateCustomer", () =>
      $fetch<UserType>(`${NUXT_PUBLIC_API_PROTOCOL}://${NUXT_PUBLIC_API_BASE_URL}/middleware/updateCustomer`, {
        headers,
        body: JSON.stringify(payload),
        method: "POST",
        credentials: "include",
      }),
    );

    useHandleError(error.value);

    state.value.user = customerData.value;
  };

  const updateContactPreference = async (payload: UpdateCustomerContactPreferenceParams) => {
    const { NUXT_PUBLIC_API_PROTOCOL, NUXT_PUBLIC_API_BASE_URL } = useRuntimeConfig().public;
    const headers = useRequestHeaders();

    const { data: customerData, error } = await useAsyncData("updateCustomerContactPreference", () =>
      $fetch<UserType>(
        `${NUXT_PUBLIC_API_PROTOCOL}://${NUXT_PUBLIC_API_BASE_URL}/middleware/updateCustomerContactPreference`,
        {
          headers,
          body: JSON.stringify(payload),
          method: "POST",
          credentials: "include",
        },
      ),
    );

    useHandleError(error.value);

    state.value.user = customerData.value;
  };

  const updateUserEmail = async (payload: UpdateUserEmailParams) => {
    const { NUXT_PUBLIC_API_PROTOCOL, NUXT_PUBLIC_API_BASE_URL } = useRuntimeConfig().public;
    const headers = useRequestHeaders();

    const { data: customerData, error } = await useAsyncData("updateUserEmail", () =>
      $fetch<UserType>(`${NUXT_PUBLIC_API_PROTOCOL}://${NUXT_PUBLIC_API_BASE_URL}/middleware/updateUserEmail`, {
        headers,
        body: JSON.stringify(payload),
        method: "POST",
        credentials: "include",
      }),
    );

    useHandleError(error.value);

    state.value.user = customerData.value;
  };

  const userCategory = state.value.user?.account?.business_type?.split("|");

  return {
    login,
    logout,
    fetchCustomer,
    isAdmin,
    isCredit,
    updateUserProfile,
    updateUserPassword,
    updateContactPreference,
    updateUserEmail,
    checkIfUserExists,
    userCategory,
    ...toRefs(state.value),
  };
};

export default useUser;
