export const removeNullValues = (
  inputObject: Record<string, any> | null | undefined,
): Record<string, any> => {
  if (inputObject === null || inputObject === undefined) {
    return {};
  }

  return Object.fromEntries(
    Object.entries(inputObject)
      .filter(([_, value]) => value !== null)
      .map(([key, value]) => [
        key,
        typeof value === "object" && !Array.isArray(value)
          ? removeNullValues(value)
          : Array.isArray(value)
          ? value.map((element) =>
              typeof element === "object" && element !== null
                ? removeNullValues(element)
                : element,
            )
          : value,
      ]),
  );
};
